import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit,OnDestroy  {

  isLoading: boolean=false;
  private loaderSubscription: Subscription;
  isLoader: boolean=false;
  constructor(
    private router: Router,
    private loaderService: LoaderService,
  ) { 

    // Spinner for lazyload modules
    router.events.forEach((event) => { 
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });

    
  }

  ngOnInit(): void {
    // this.isLoading = this.loaderService.getIsLoading();

    this.loaderSubscription = this.loaderService.isLoading.subscribe((status: boolean) => {
      this.isLoader = status;
    });
  }
  ngOnDestroy(): void {
    this.loaderSubscription.unsubscribe();
  }
}
