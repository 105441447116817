import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { AuthGuard } from './shared/services/auth.gaurd';
import { BaseComponent } from './modules/layout/base/base.component';


const routes: Routes = [

  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'sales-lead',
        loadChildren: () => import('./modules/sales-center/sales-lead/sales-lead.module').then(m => m.SalesLeadModule)
      },
      {
        path: 'sales-accounts',
        loadChildren: () => import('./modules/sales-center/sales-accounts/sales-accounts.module').then(m => m.SalesAccountsModule)
      },
      {
        path: 'sales-opportunity',
        loadChildren: () => import('./modules/sales-center/sales-opportunity/sales-opportunity.module').then(m => m.SalesOpportunityModule)
      },
      {
        path: 'sales-territory',
        loadChildren: () => import('./modules/sales-center/sales-territory/sales-territory.module').then(m => m.SalesTerritoryModule)
      },
      {
        path: 'apps',
        loadChildren: () => import('./views/pages/apps/apps.module').then(m => m.AppsModule)
      },
      {
        path: 'ui-components',
        loadChildren: () => import('./views/pages/ui-components/ui-components.module').then(m => m.UiComponentsModule)
      },
      {
        path: 'advanced-ui',
        loadChildren: () => import('./views/pages/advanced-ui/advanced-ui.module').then(m => m.AdvancedUiModule)
      },
      {
        path: 'form-elements',
        loadChildren: () => import('./views/pages/form-elements/form-elements.module').then(m => m.FormElementsModule)
      },
      {
        path: 'advanced-form-elements',
        loadChildren: () => import('./views/pages/advanced-form-elements/advanced-form-elements.module').then(m => m.AdvancedFormElementsModule)
      },
      {
        path: 'charts-graphs',
        loadChildren: () => import('./views/pages/charts-graphs/charts-graphs.module').then(m => m.ChartsGraphsModule)
      },
      {
        path: 'tables',
        loadChildren: () => import('./views/pages/tables/tables.module').then(m => m.TablesModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/pages/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'general',
        loadChildren: () => import('./views/pages/general/general.module').then(m => m.GeneralModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
  
    ]
  },
  // { path:'',  redirectTo: 'auth/signin', pathMatch: 'prefix'},
  { path:'auth', loadChildren: () => import('./modules/sessions/sessions.module').then(m => m.SessionsModule) },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  // { path: '**', redirectTo: 'error', pathMatch: 'full' }
  { path: '**',  redirectTo: 'auth/signin', pathMatch: 'prefix' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
