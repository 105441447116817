import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private authService: MsalService,
        private toastr: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            if (err.status === 401 || err.status === 403) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                this.authService.logout();
            }
            else {
                if (err.status === 0) {
                    //   this.loaderService.hide();
                    this.toastr.error("Coudn't connect to the server OR server not responding", undefined)
                }
                else if (err.status === 400) {
                    this.toastr.error(err.error.error.message ?? `${err.message}\n ${JSON.stringify(err.error?.error)}`, undefined)
                }
                else if (err.status === 500) {
                    this.toastr.error(err.error.error.message, undefined);
                }
                else {
                    this.toastr.error("Something went wrong!", undefined);
                }
                return throwError(err);
            }

            const error = err.message || err.statusText;
            return throwError(error);
        }))
    }

}