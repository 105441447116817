
import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalStoreService } from './local-store.service';
import { PermissionService } from './permission.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private store: LocalStoreService,
    private permissionService: PermissionService,
    private translate: TranslateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.auth.authenticated) {
      var permissions = this.store.getItem("permissions");
      this.permissionService.storePermissions(permissions);

      var languages = this.store.getItem("languages");
      var translationsData = this.store.getItem("languageTexts");
      var defaultLanguage = this.store.getItem('defaultLanguage');

      this.translate.setTranslation(defaultLanguage, translationsData);
      this.translate.setDefaultLang(defaultLanguage);

      return true;
    }

    var token = this.auth.getToken();

    // if (!token) {
    //   // this.router.navigateByUrl('/auth/signin');
    //   this.router.navigate(['/auth/signin'], { queryParams: { returnUrl: window.location.pathname } });
    // }

    if (!token || (token && !this.auth.authenticated)) {
      const returnUrl = window.location.pathname !== '/' ? window.location.pathname : undefined;
      if (returnUrl) {
        this.router.navigate(['/auth/signin'], { queryParams: { returnUrl: returnUrl } });
      } else {
        this.router.navigate(['/auth/signin']);
      }
    }

    return false;
  }
}

