import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private eventSubject = new Subject<void>();
  event$ = this.eventSubject.asObservable();
  private eventContact = new Subject<void>();
  eventcontact$ = this.eventContact.asObservable();
  private eventAbout = new Subject<void>();
  eventAbout$ = this.eventAbout.asObservable();

  emitEvent() {
    this.eventSubject.next();
  }
  emitcontact() {
    this.eventContact.next();
  }
  emitAbout() {
    this.eventAbout.next();
  }
}