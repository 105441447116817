import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { b2cPolicies } from './modules/sessions/auth-config';
type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

declare const FB: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'nobleui-angular';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document){}

  ngOnInit(): void {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    this.document.body.classList.add('sidebar-dark');

  }

  // initializeFacebook(authService: AuthService) {
  //   // @ts-ignore
  //   window['fbAsyncInit'] = function () {

  //     FB.init({
  //       appId: environment.facebookAppId,
  //       xfbml: true,
  //       version: 'v16.0'
  //     });

  //     FB.AppEvents.logPageView();

  //     // auto authenticate with the api if already logged in with facebook
  //     FB.getLoginStatus(({ authResponse }: any) => {
  //       if (authResponse) {
  //         authService.facebookLogin({ accessToken: authResponse.accessToken })
  //           .subscribe(resp => {
  //             console.log(resp);
  //           });
  //       }
  //       // else {
  //       //   resolve();
  //       // }
  //     });
  //   };

  //   // load facebook sdk script
  //   (function (d, s, id) {
  //     var js: any, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) { return; }
  //     js = d.createElement(s); js.id = id;
  //     js['src'] = "https://connect.facebook.net/en_US/sdk.js";
  //     if (fjs['parentNode']) {
  //       fjs['parentNode'].insertBefore(js, fjs);
  //     }
  //   }(document, 'script', 'facebook-jssdk'));

  // }

}
