import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class LoaderService {
  
  constructor() { }
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  display(value: boolean) {
      this.status.next(value);
  }
  setIsLoading(value: boolean) {
    this.isLoading.next(value);
  }

}