import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ToastrModule } from "ngx-toastr";
import { SharedComponentsModule } from "./components/shared-components.module";
import { SharedDirectivesModule } from "./directives/shared-directives.module";
import { SharedPipesModule } from "./pipes/shared-pipes.module";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgModule } from "@angular/core";
// import { UrlHelperService } from "./services/url-helper.service";
import { TranslateModule } from "@ngx-translate/core";
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from "./confirmation-dialog/confirmation-dialog.service";
import { NullEmptyToDashPipe } from "./pipes/null-empty-to-dash.pipe";
import { LoaderComponent } from "./components/loader/loader.component";
import { ImportResultDialogComponent } from "./import-result-dialog/import-result-dialog.component";
import { ImportResultService } from "./import-result-dialog/import-result-dialog.service";
import { EventService } from "./services/event.service";


@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
}),
    NgbModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    SharedPipesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    TranslateModule
  ],
  providers: [
    SharedPipesModule,
    ConfirmationDialogService,
    ImportResultService,
    EventService
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgbModule,
    ToastrModule,
    NullEmptyToDashPipe,
    TranslateModule,
    LoaderComponent
  ],
  declarations: [
    ConfirmationDialogComponent,
    NullEmptyToDashPipe,
    LoaderComponent,
    ImportResultDialogComponent
  ],

})
export class SharedModule { }
