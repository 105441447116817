import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private myPermissions:any = [];
  constructor() { }

  storePermissions(permissions:any) {
    this.myPermissions = permissions
  }

  isPermitted(permission:any) {
    if (this.myPermissions) {
      return this.myPermissions.includes(permission);
    }
    else {
      return false
    }
  }


  

}
