const BACKEND_URL = 'https://dev-service.salescenter.ai/'
const OauthProtocol = 'http';
const OauthClientId = '';
const OauthPort = '';
const OauthSecret = '';

export const environment = {
  apiBaseUrl: BACKEND_URL + 'api/services/app',
  baseUrl: BACKEND_URL,
  assetUrl: BACKEND_URL + '/SmartOfficerAttachment',
  production: true,
  googleClientId: "463817211807-kd2tc0n3us50bc4qt5srp9jvr7qoa4oi.apps.googleusercontent.com",
  facebookAppId: "920731608966698",
  oauth: {
    port: OauthPort,
    protocol: OauthProtocol,
    path: 'api/TokenAuth/Authenticate',
    client_id: OauthClientId,
    secret: OauthSecret,
  },
  urls: {
    //user managment
    // USERS_GETALL: '/User/GetAll',
    USERS_GETALL: '/User/GetAllUsers',
    USER_GETBYID: '/User/GetUserById',
    ROLES_GETALL: '/Role/GetAll',
    USER_POST: '/User/Create',
    USER_CreateNew: '/User/CreateNew',
    USER_UDPATE: '/User/Update',
    ROLE_GET: '/Role/Get',
    ROLE_UPDATE: '/Role/Update',
    User_Reset_Password_Request: '/User/ResetPasswordRequest',
    User_Check_OTP: '/User/CheckOTP',
    User_Change_Password_ByOTP: '/User/ChangePasswordByOTP',
    User_Expire_Old_OTP: '/User/ExpireOldOTP',
    User_Enable: '/User/Enable',
    User_Disable: '/User/Disable',
    User_GrantAccess: '/User/GrantAccess',

    Is_Tenant_Available: "/Account/IsTenantAvailable",

    TICKET_GETALL: '/Ticket/GetTickets',
    TICKET_ONMAP: '/Ticket/GetAllTicketByCurrentUser',
    TICKET_GETBYID: '/Ticket/GetTicketById',

    ATTENDANCE_GETALL: '/OfficerAttendance/GetAttendanceLog',

    TICKET_GETTICKETTYPE: '/Ticket/GetAllTicketType',

    ROLE_CREATE: '/Role/Create',

    PERMISSIONS_GET: '/Role/GetAllPermissions',

    CREATE_HIERARCHY: '/Department/AddDepartmentUserMapping',
    GET_HIERARCHY: '/Department/GetUserDepartmentMapping',
    USERS_BY_ROLE: '/User/GetUserByRole',

    AUDIT_LOGS_GET: '/AuditLog/GetAuditLogs',

    LATEST_USERS_LOCATION_GET: '/UserLocation/GetLatestUsersLocation',

    USER_GET: '/User/Get',

    AUDIT_TYPES_GET: '/Auditor/GetAuditTypes',

    Notification_GET_ALL: '/Notification/GetNotifications',
    Notification_Get_Unseen_Notification_Count: '/Notification/GetUnseenNotificationCount',
    Notification_Update_Notification: '/Notification/UpdateNotification',
    Notification_Save_Task_Group: '/Notification/SaveTaskGroup',
    Notification_Get_Notification_Groups: '/Notification/GetNotificationGroups',
    Notification_Get_NotificationGroup_ById: '/Notification/GetNotificationGroupById',
    Notification_Update_NotificationGroup: '/Notification/UpdateNotificationGroup',
    Notification_Delete_NotificationGroup: '/Notification/DeleteNotificationGroup',

    Upload_Upload_Web_Single_File: '/Upload/UploadWebSingleFile',
    Upload_Ticket_Request_Attachment: '/Upload/UploadTicketRequestAttachment',
    Upload_General_Single_File: '/Upload/UploadGeneralSingleFile',
    Upload_Download: '/Upload/Download',
    Upload_Download_Ticket_Request_Attachment: '/Upload/DownloadTicketRequestAttachment',
    Upload_Delete_File: '/Upload/DeleteFile',
    Upload_Download_Export_To_Excel_TempFile: '/Upload/DownloadExportToExcelTempFile',

    Reporting_Expense_ReportToExcel: '/Reporting/ExpenseReportToExcel',
    Reporting_MainCategories_ReportToExcel: '/Reporting/MainCategoriesReportToExcel',

    POSUploadFile_General_Single_File: '/POSUploadFile/UploadGeneralSingleFile',
    POSUploadFile_Download_Export_To_Excel_TempFile: '/POSUploadFile/DownloadExportToExcelTempFile',

    // SalesTerritory​
    SalesTerritory_GetAll: '/SalesTerritory/GetAll',
    SalesTerritory_GetById: '/SalesTerritory/GetById',
    SalesTerritory_Create: '/SalesTerritory/Create',
    SalesTerritory_Update: '/SalesTerritory/Update',
    SalesTerritory_Delete: '/SalesTerritory/Delete',
    SalesTerritory_GetAllGlobalRegion: '/SalesTerritory/GetAllGlobalRegion',
    SalesTerritory_GetAllRegion: '/SalesTerritory/GetAllRegion',
    SalesTerritory_GetAllSubRegion: '/SalesTerritory/GetAllSubRegion',
    SalesTerritory_GetAllTerritory: '/SalesTerritory/GetAllTerritory',

    // SalesLeads
    SalesLeads_GetAll: '/SalesLeads/GetAll',
    SalesLeads_Delete: '/SalesLeads/Delete',
    SalesLeads_GetAllLeadStatus: '/SalesLeads/GetAllLeadStatus',
    SalesLeads_UpdateLeadStatus: '/SalesLeads/UpdateLeadStatus',
    SalesLeads_Update: '/SalesLeads/Update',
    SalesLeads_GetLeadDetailById: '/SalesLeads/GetLeadDetailById',
    SalesLeads_UpdateLeadInfo: '/SalesLeads/UpdateLeadInfo',
    SalesLeads_UpdateLeadClassification: '/SalesLeads/UpdateLeadClassification',
    SalesLeads_UpdateLeadContact: '/SalesLeads/UpdateLeadContact',
    SalesLeads_UpdateLeadAddress: '/SalesLeads/UpdateLeadAddress',
    SalesLeads_UpdateLeadInfluence: '/SalesLeads/UpdateLeadInfluence',
    SalesLeads_UpdateLeadTerritory: '/SalesLeads/UpdateLeadTerritory',
    // SalesLeads_UpdateLeadSystem: '/SalesLeads/UpdateLeadSystem',
    SalesLeads_CreateLead: '/SalesLeads/CreateLead',
    SalesLeads_GetLeadOwnerByZipCode: '/SalesLeads/GetLeadOwnerByZipCode',
    SalesLeads_GetLeadInfoById: '/SalesLeads/GetLeadInfoById',
    SalesLeads_GetLeadClassificationById: '/SalesLeads/GetLeadClassificationById',
    SalesLeads_GetLeadContactById: '/SalesLeads/GetLeadContactById',
    SalesLeads_GetLeadAddressById: '/SalesLeads/GetLeadAddressById',
    SalesLeads_GetLeadInfluenceById: '/SalesLeads/GetLeadInfluenceById',
    SalesLeads_GetLeadTerritoryById: '/SalesLeads/GetLeadTerritoryById',
    SalesLeads_GetLeadSystemById: '/SalesLeads/GetLeadSystemById',
    SalesLeads_GetAccordionStatusById: '/SalesLeads/GetAccordionStatusById',
    SalesLeads_GetLeadInformationById: '/SalesLeads/GetLeadInformationById',
    SalesLeads_GetLeadActivityLogs: '/SalesLeads/GetLeadActivityLogs',
    SalesLeads_GetAddressesById: '/SalesLeads/GetAddressesById',
    SalesLeads_GetContactsById: '/SalesLeads/GetContactsById',
    SalesLeads_UpdateContacts: '/SalesLeads/UpdateContacts',
    SalesLeads_UpdateAddress: '/SalesLeads/UpdateAddress',
    SalesLeads_CreateContacts: '/SalesLeads/CreateContacts',
    SalesLeads_CreateAddress: '/SalesLeads/CreateAddress',
    SalesLeads_DeleteContact: '/SalesLeads/DeleteContact',
    SalesLeads_DeleteAddress: '/SalesLeads/DeleteAddress',

    // SalesOpportunity
    SalesOpportunity_GetAll: '/SalesOpportunity/GetAll',
    SalesOpportunity_Delete: '/SalesOpportunity/Delete',
    SalesOpportunity_Update: '/SalesOpportunity/Update',
    SalesOpportunity_GetOpportunityDetailById: '/SalesOpportunity/GetOpportunityDetailById',
    SalesOpportunity_GetOpportunityClassificationById: '/SalesOpportunity/GetOpportunityClassificationById',
    SalesOpportunity_UpdateOpportunityClassification: '/SalesOpportunity/UpdateOpportunityClassification',
    SalesOpportunity_GetOpportunityStageInfoById: '/SalesOpportunity/GetOpportunityStageInfoById',
    SalesOpportunity_UpdateOpportunityStageInfo: '/SalesOpportunity/UpdateOpportunityStageInfo',
    SalesOpportunity_UpdateOpportunityKeyFields: '/SalesOpportunity/UpdateOpportunityKeyFields',
    SalesOpportunity_GetOpportunityKeyFieldsById: '/SalesOpportunity/GetOpportunityKeyFieldsById',
    SalesOpportunity_GetOpportunityInfoById: '/SalesOpportunity/GetOpportunityInfoById',
    SalesOpportunity_UpdateOpportunityInfo: '/SalesOpportunity/UpdateOpportunityInfo',
    SalesOpportunity_UpdateOpportunityFinance: '/SalesOpportunity/UpdateOpportunityFinance',
    SalesOpportunity_GetOpportunityFinanceById: '/SalesOpportunity/GetOpportunityFinanceById',
    SalesOpportunity_UpdateOpportunitySalesInfluence: '/SalesOpportunity/UpdateOpportunitySalesInfluence',
    SalesOpportunity_GetOpportunitySalesInfluenceById: '/SalesOpportunity/GetOpportunitySalesInfluenceById',
    SalesOpportunity_GetOpportunityLeadInfluenceById: '/SalesOpportunity/GetOpportunityLeadInfluenceById',
    SalesOpportunity_UpdateOpportunityLeadInfluence: '/SalesOpportunity/UpdateOpportunityLeadInfluence',
    SalesOpportunity_GetOpportunityPricingInfoById: '/SalesOpportunity/GetOpportunityPricingInfoById',
    SalesOpportunity_UpdateOpportunityPricingInfo: '/SalesOpportunity/UpdateOpportunityPricingInfo',
    SalesOpportunity_GetAllLeadOpportunities: '/SalesOpportunity/GetAllLeadOpportunities',
    SalesOpportunity_GetOpportunityActivityLogs: '/SalesOpportunity/GetOpportunityActivityLogs',
    SalesOpportunity_GetOpportunityInformationById: '/SalesOpportunity/GetOpportunityInformationById',

    // SalesAccounts
    SalesAccounts_GetAll: '/SalesAccounts/GetAll',
    SalesAccounts_Delete: '/SalesAccounts/Delete',
    SalesAccounts_Update: '/SalesAccounts/Update',
    SalesAccounts_GetAccountsDetailById: '/SalesAccounts/GetAccountDetailById',

    // Languages
    Get_AllLanguages: '/TenantLanguage/GetAllLanguages',
    Update_LanguagesLables: '/TenantLanguage/UpdateLanguagesLables',
    Get_All_Language_Text: '/TenantLanguage/GetAllLanguageText',
    AutoTranslateLanguageText: '/TenantLanguage/AutoTranslateLanguageText',

    // SalesLookups
    SalesLookups_GetAllLeadStatus: '/SalesLookups/GetAllLeadStatus',
    SalesLookups_GetAllLeadStatusReason: '/SalesLookups/GetAllLeadStatusReason',
    SalesLookups_GetAllLeadPronouns: '/SalesLookups/GetAllLeadPronouns',
    SalesLookups_GetAllLeadAccounts: '/SalesLookups/GetAllLeadAccounts',
    SalesLookups_GetAllLeadEmployees: '/SalesLookups/GetAllLeadEmployees',
    SalesLookups_GetAllLeadsType: '/SalesLookups/GetAllLeadsType',
    SalesLookups_GetAllLeadsTradeshowAttendanceType: '/SalesLookups/GetAllLeadsTradeshowAttendanceType',
    SalesLookups_GetAllLeadsSource: '/SalesLookups/GetAllLeadsSource',
    SalesLookups_GetAllLeadsCustomerCategory: '/SalesLookups/GetAllLeadsCustomerCategory',
    SalesLookups_GetAllLeadsSalutation: '/SalesLookups/GetAllLeadsSalutation',
    SalesLookups_GetAllLeadsSourceDetail: '/SalesLookups/GetAllLeadsSourceDetail',
    SalesLookups_GetAllLeadsBrandType: '/SalesLookups/GetAllLeadsBrandType',
    SalesLookups_GetAllLeadsChannel: '/SalesLookups/GetAllLeadsChannel',
    SalesLookups_GetAllLeadsDecisionMaking: '/SalesLookups/GetAllLeadsDecisionMaking',
    SalesLookups_GetAllLeadsTypeOfBusiness: '/SalesLookups/GetAllLeadsTypeOfBusiness',
    SalesLookups_GetAllLeadsSalesGroup: '/SalesLookups/GetAllLeadsSalesGroup',
    SalesLookups_GetAllLeadsSubChannel: '/SalesLookups/GetAllLeadsSubChannel',
    SalesLookups_GetAllLeadsLocation: '/SalesLookups/GetAllLeadsLocation',
    SalesLookups_GetAllLeadsVerifiedReferral: '/SalesLookups/GetAllLeadsVerifiedReferral',
    SalesLookups_GetAllLeadsCSMTerritoryName: '/SalesLookups/GetAllLeadsCSMTerritoryName',
    SalesLookups_GetAllLeadsLookups: '/SalesLookups/GetAllLeadsLookups',
    SalesLookups_GetSelectedItemLables: '/SalesLookups/GetSelectedItemLables',
    SalesLookups_UpdateLookupLables: '/SalesLookups/UpdateLookupLables',
    SalesLookups_AutoTranslateLookupLables: '/SalesLookups/AutoTranslateLookupLables',
    SalesLookups_GetAllOpportunityLocationCount: '/SalesLookups/GetAllOpportunityLocationCount',
    SalesLookups_GetAllOpportunityPurchaseDecisionMaker: '/SalesLookups/GetAllOpportunityPurchaseDecisionMaker',
    SalesLookups_GetAllPaymentType: '/SalesLookups/GetAllPaymentType',
    SalesLookups_GetAllDownPaymentType: '/SalesLookups/GetAllDownPaymentType',
    SalesLookups_GetAllFinancingTypes: '/SalesLookups/GetAllFinancingTypes',
    SalesLookups_GetAllCompetitor: '/SalesLookups/GetAllCompetitor',
    SalesLookups_GetAllOpportunityStageReason: '/SalesLookups/GetAllOpportunityStageReason',
    SalesLookups_GetAllTypeOfSale: '/SalesLookups/GetAllTypeOfSale',
    SalesLookups_GetAllNumberOfSystems: '/SalesLookups/GetAllNumberOfSystems',
    SalesLookups_GetAllOpportunityStage: '/SalesLookups/GetAllOpportunityStage',
    SalesLookups_GetAllSalesSource: '/SalesLookups/GetAllSalesSource',
    SalesLookups_GetAllSalesSourceDetail: '/SalesLookups/GetAllSalesSourceDetail',
    SalesLookups_GetAllCities: '/SalesLookups/GetAllCities',
    SalesLookups_GetAllStates: '/SalesLookups/GetAllStates',
    SalesLookups_GetAllCountries: '/SalesLookups/GetAllCountries',

    //  Files
    File_GetTerritoryTemplate_terriroty_template: '/File/GetTerritoryTemplate',
    File_GetTerritoryExport: '/File/GetTerritoryExport',
    File_GetTerritoryImport: '/File/GetTerritoryImport',

    //  Lead Convert
    SalesConversions_ConvertLeadToAccount: '/SalesConversions/ConvertLeadToAccount',
    SalesConversions_ConvertLeadToOpportunity: '/SalesConversions/ConvertLeadToOpportunity',
    SalesConversions_ConvertOpportunityToAccount: '/SalesConversions/ConvertOpportunityToAccount',

    // SalesCustomFields
    SalesCustomFields_GetAllCustomFields: '/SalesCustomFields/GetAllCustomFields',
    SalesCustomFields_UpsertCustomFieldData: '/SalesCustomFields/UpsertCustomFieldData',
  },
  powerbiURLs: {},
  external: {}

};

