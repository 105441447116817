import { HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { LocalStoreService } from './local-store.service';
import { PermissionService } from './permission.service';
import { RestService } from './rest.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  currentUser: any;
  constructor(
    private authService: AuthService,
    private restService: RestService,
    private permissionService: PermissionService,
    private store: LocalStoreService,
    private router: Router,
    private translate: TranslateService,
  ) {

  }

  getUser(): Observable<any> {

    let userId = this.authService.getuserId();
    return this.restService.get(`${environment.urls.USER_GETBYID}?id=${userId}`).pipe(map((response: { result: any; }) => {
      this.currentUser = response.result;
      this.permissionService.storePermissions(response.result.permissions);

      this.store.setItem('permissions', response.result.permissions);
      let translationsData = {} as any;

      response?.result?.languageTexts?.forEach((translationText: any) => {
        translationsData[translationText?.key] = translationText.value;
      });

      this.translate.setTranslation(response.result?.defaultLanguage, translationsData);
      this.translate.setDefaultLang(response.result?.defaultLanguage);

      this.store.setItem('languages', response.result.languages);
      this.store.setItem('languageTexts', translationsData);
      this.store.setItem('defaultLangId', response.result?.languages.find((x: any) => x.name == response.result?.defaultLanguage)?.id);
      this.store.setItem('defaultLanguage', response.result?.defaultLanguage);
      return response
    }))

  }
  getUserInfo(): Observable<any> {

    if (this.currentUser != null) {
      return of(this.currentUser)
    } else {
      let userId = this.authService.getuserId();
      return this.restService.get(`${environment.urls.USER_GETBYID}?id=${userId}`).pipe(map((response: { result: { permissions: any; }; }) => {
        this.currentUser = response.result;
        return this.currentUser
      }));
    }

  }


  isInRole(role: string) {
    if (this.currentUser) {
      return this.currentUser.roleNames.includes(role.toUpperCase())
    }
    else {
      return false;
    }
  }

  signout() {
    this.currentUser = undefined
    let lang = localStorage.getItem('lang');
    localStorage.clear();
    this.authService.authenticated = false;
    this.store.setItem("uis_login_status", false);
    localStorage.setItem('lang', lang!);
    this.router.navigateByUrl("/auth/signout");
  }

}
