import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-import-result-dialog',
  templateUrl: './import-result-dialog.component.html',
})
export class ImportResultDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: any;
  @Input() btnCancelText: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public accept() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.close(false);
  }

}
