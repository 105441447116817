<div class="main-wrapper" *ngIf="!isLoader; else loader">

  <!-- partial:sidebar -->
  <app-sidebar></app-sidebar>
  <!-- partial -->

  <div class="page-wrapper">

    <!-- partial:navbar -->
    <app-navbar></app-navbar>
    <!-- partial -->

    <div class="page-content pb-1 pt-2">

      <!-- Spinner for lazyload modules -->
      <div *ngIf="isLoading" class="spinner-wrapper">
        <div class="spinner">Loading...</div>
      </div>

      <div contentAnimate *ngIf="!isLoading">
        <router-outlet></router-outlet>
      </div>

    </div>

    <!-- partial:footer -->
    <!-- <app-footer></app-footer> -->
    <!-- partial -->

  </div>


</div>
<ng-template #loader>
  <div class="spinner-wrapper t-loader">
  <div class="spinner ">Loading...</div>
  <p class="mt-3">Loading please wait..</p>
</div>
</ng-template>