export enum UserPermissions {


    USER_VIEW = 'USER_VIEW',
    USER_CREATE = 'USER_CREATE',
    USER_UPDATE = 'USER_UPDATE',

  ViewHomeMenu = 'ViewHomeMenu',

  ViewAuditLog = 'ViewAuditLog',
    DASHBOARD = 'DASHBOARD',
  // Point Of Sale Module Permissions
  ViewPOSMenu='ViewPOSMenu',
  ViewExpenseMenu='ViewExpenseMenu',
  ViewHistoryMenu='ViewHistoryMenu',
  ViewAllStockMenu='ViewAllStockMenu',
  ViewAccountingMenu='ViewAccountingMenu',
  ViewMasterCategoriesMenu='ViewMasterCategoriesMenu',
  ViewPurchaseOrderMenu='ViewPurchaseOrderMenu',
  ViewReturnOrderMenu='ViewReturnOrderMenu',
  ViewSettingMenu='ViewSettingMenu',
  ViewReportsMenu='ViewReportsMenu',


  ViewUsersMenu = 'ViewUsersMenu',
  ViewRolesMenu = 'ViewRolesMenu',
  ViewPermissionsMenu = 'ViewPermissionsMenu',


}