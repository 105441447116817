import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { RestService } from "./rest.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RolesEnum } from "../enum/Roles";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { LoaderService } from "./loader.service";
import { Observable, map } from "rxjs";
// import { UrlHelperService } from "./url-helper.service";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: "root"
})

export class AuthService {
  //Only for demo purpose
  authenticated = true;
  url = environment.baseUrl;
  private superAdminRoles: string[] = [
    RolesEnum.DepartmentManager,
  ]

  constructor(
    private store: LocalStoreService,
    private router: Router,
    private restService: RestService,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private loaderService: LoaderService
  ) {
    this.checkAuth();
  }

  checkAuth() {
    // this.authenticated = this.store.getItem("uis_login_status");
    this.authenticated = this.isLoggedIn();
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  getuser() {
    return this.store.getItem("firstName") + " " + this.store.getItem("lastName");
  }

  getuserId() {
    return this.store.getItem("userId")
  }
  getuserRole() {
    return this.store.getItem("roleIds")
  }

  getAgencyId() {
    return this.store.getItem("agencyId")
  }

  signin(credentials: any) {
    // const url = this.urlHelper.getUrl('oauth');
    // return this.httpClient.post(url!, credentials).pipe(map((response: any) => {

    //   let result = response['result'];
    //   Object.keys(result).forEach(key => {

    //     this.store.setItem(key, result[key]);
    //   });
    //   this.authenticated = true;
    //   this.store.setItem("uis_login_status", true);
    //   this.setSession(result['expireInSeconds']);
    // }));
    // return of({}).pipe(delay(1500));
  }

  authenticateWithAzureAD(model: any): Observable<any> {
    return this.httpClient.post(`${this.url}api/TokenAuth/AuthenticateWithAzureAD`, model).pipe(map((response: any) => {

      let result = response['result'];
      Object.keys(result).forEach(key => {
        this.store.setItem(key, result[key]);
      });
      this.authenticated = true;
      this.store.setItem("uis_login_status", true);
      this.setSession(result['expireInSeconds']);

      return response;
    }));
  }

  googleLogin(model: any): Observable<any> {
    return this.httpClient.post(`${this.url}api/TokenAuth/AuthenticateWithGoogle`, model).pipe(map((response: any) => {

      let result = response['result'];
      Object.keys(result).forEach(key => {

        this.store.setItem(key, result[key]);
      });

      this.authenticated = true;
      this.store.setItem("uis_login_status", true);
      this.setSession(result['expireInSeconds']);

    }));
  }

  getToken() {
    return this.store.getItem('accessToken');
  }

  getEncryptedToken() {
    return this.store.getItem('encryptedAccessToken');
  }

  roles(): string[] {
    let arr = this.store.getItem('roleIds');
    if (arr) {
      //let roles = arr.split(",");
      // return roles;
    }
    return arr;
    // return [''];
  }

  isSuperAdmin(): boolean {
    let rolesMatched = this.roles().filter(value => -1 !== this.superAdminRoles.indexOf(value));
    if (rolesMatched.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  private setSession(expiresIn: any) {
    const expiresAt = moment().add(expiresIn, 'second');
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
  }

  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration!);
    return moment(expiresAt);
  }

  facebookLogin(model: any): Observable<any> {
    return this.httpClient.post(`${this.url}api/TokenAuth/AuthenticateWithFacebook`, model).pipe(map((response: any) => {

      let result = response['result'];
      Object.keys(result).forEach(key => {

        this.store.setItem(key, result[key]);
      });

      this.authenticated = true;
      this.store.setItem("uis_login_status", true);
      this.setSession(result['expireInSeconds']);
    }));
  }
}
