import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AllowAlphabetsOnlyDirective } from "./allow-alphabets-only";
import { AllowAlphanumericOnlyDirective } from "./allow-alphanumeric-only.directive";
import { DropdownAnchorDirective } from "./dropdown-anchor.directive";
import { DropdownLinkDirective } from "./dropdown-link.directive";
import { AppDropdownDirective } from "./dropdown.directive";
import { FileDndDirective } from "./file-dnd.directive";
import { FullScreenWindowDirective } from "./full-screen.directive";
import { HighlightjsDirective } from "./highlightjs.directive";
import { PositiveNumberOnlyDirective } from "./positive-number-only.directive";
import { ShowYearOnlyDirective } from "./show-year-only.directive";


const directives = [
  DropdownAnchorDirective,
  DropdownLinkDirective,
  AppDropdownDirective,
  HighlightjsDirective,
  FullScreenWindowDirective,
  FileDndDirective,
  PositiveNumberOnlyDirective,
  AllowAlphanumericOnlyDirective,
  AllowAlphabetsOnlyDirective,
      ShowYearOnlyDirective
   ];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: directives,
  exports: directives
})
export class SharedDirectivesModule { }
