<div class="modal-header">
    <h4 class="modal-title">{{ title|translate }}</h4>
      <button type="button" class="close btn btn-outline-primary" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ul>
        <li>{{ message?.addedTerritoriesMsg|translate }} </li>
        <li>{{ message?.failedTerritoriesMsg|translate }} </li>
      </ul>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="accept()">{{ btnCancelText|translate }}</button>
    </div>