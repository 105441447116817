import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportResultDialogComponent } from './import-result-dialog.component';

@Injectable()
export class ImportResultService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: any,
    btnCancelText: string = 'cancel',
    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ImportResultDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

}
