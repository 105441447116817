import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { RestService } from 'src/app/shared/services/rest.service';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { MsalService } from '@azure/msal-angular';
import { UserService } from 'src/app/shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  currentUser: any;
  User: any;
  loader: boolean;
  initials: any;
  languages: any;
  defaultLanguage: any = "en";
  selectedLanguage: any;
  loading: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private authService: AuthService,
    private msalService: MsalService,
    private store: LocalStoreService,
    private userService: UserService,
    private translate: TranslateService,
    private restService: RestService,
    private loaderService: LoaderService,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getLanguages();
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e: Event) {

    this.currentUser = undefined
    let lang = localStorage.getItem('lang');
    localStorage.clear();
    this.authService.authenticated = false;
    this.store.setItem("uis_login_status", false);
    localStorage.setItem('lang', lang!);

    this.msalService.logout();
    // this.router.navigateByUrl("/auth/signout");
  }

  getCurrentUser() {
    this.loader = true;
    this.userService.getUserInfo().subscribe((resp: any) => {
      this.User = resp;
      this.initials = this.User.fullName.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
      this.loader = false;
    }, error => {
      this.loader = false;
    });
  }

  getLanguages() {
    this.languages = this.store.getItem("languages");
 
    for (let i = 0; i < this.languages?.length; i++) {
      const lang = this.languages[i];
      if (lang.name === this.store.getItem('defaultLanguage')) {
        this.selectedLanguage = lang.displayName;
        break;
      }
    }
  }

  changeLanguage(language: any): void {
    this.loaderService.setIsLoading(true);

    this.defaultLanguage = language.name;

    this.loading = true;
    this.restService.get(`${environment.urls.Get_All_Language_Text}?source=${language.name}&languageName=${language.displayName}`)
      .subscribe((response) => {

        if (response.result.length > 0) {
          this.selectedLanguage = language.displayName;

          let translationsData = {} as any;

          response?.result?.forEach((translationText: any) => {
            translationsData[translationText?.key] = translationText.value;
          });

          this.translate.setTranslation(this.defaultLanguage, translationsData);
          this.translate.setDefaultLang(this.defaultLanguage);
  
          this.store.setItem('languageTexts', translationsData);
          this.store.setItem('defaultLanguage', this.defaultLanguage);
          this.store.setItem('defaultLangId', language.id);

          this.loaderService.setIsLoading(false);
          this.loading = false;
          // this.toastr.success(`Language translated to : ${language.displayName}`);
        }
        else {
          this.loaderService.setIsLoading(false);
          this.loading = false;
          this.toastr.error(`No data found for : ${language.displayName}`);
        }
      });
  }

}
