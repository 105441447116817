import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SidePanelComponent } from './side-panel/side-panel.component';


const components = [
  BtnLoadingComponent,
  SidePanelComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    // LayoutsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    PerfectScrollbarModule,
    NgbModule
  ],
  declarations: components,
  exports: components,
  providers:[]
})
export class SharedComponentsModule { }
