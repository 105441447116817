import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcerptPipe } from './excerpt.pipe';
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import { NullEmptyToDashPipe } from './null-empty-to-dash.pipe';

const pipes = [
  ExcerptPipe,
  GetValueByKeyPipe,
  RelativeTimePipe,
  NullEmptyToDashPipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations:[
    ExcerptPipe,
    GetValueByKeyPipe,
    RelativeTimePipe
  ],
  // exports: pipes,
  providers:[
    ExcerptPipe,
    GetValueByKeyPipe,
    RelativeTimePipe
  ]
})
export class SharedPipesModule { }
