import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { AuthService } from './shared/services/auth.service';
import { LocalStoreService } from './shared/services/local-store.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService,
    private store: LocalStoreService,
  ) { }
  private clone: any
  intercept(request: any, next: any) {
    let lang = this.store.getItem('defaultLangId');
    if (!lang) {
      lang = 1;
    }
    if (request.url.includes(environment.apiBaseUrl)) {
      this.clone = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.authService.getToken()}`,
          'Accept-Language': lang.toString()
        }
      });
    } else {
      this.clone = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.authService.getToken()}`,
          'Accept-Language': lang.toString()
        }
      });
    }

    return next.handle(this.clone);
  }
}
